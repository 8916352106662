// noinspection JSNonASCIINames,NonAsciiCharacters
// noinspection SpellCheckingInspection

export default {
    Planned: "Rejalashtirilgan",
    Arrived: "Yetib keldi",
    arrived: "Yetib keldi",
    Appointments: "Uchrashuvlar",
    Board: "Bugungi",
    List: "Ro’yxat",
    Materials: "Materiallar",
    Store: "Ombor",
    Calendar: "Kalendar",
    Customers: "Mijozlar",
    customers: 'Mijozlar',
    Patients: "Bemorlar",
    Patient: "Bemor",
    Customer: "Mijoz",
    Custom: "Foydalanuvchi",
    Products: "Mahsulotlar",
    Product: "Mahsulotlar",
    Categories: "Toifalar",
    Category: "Toifa",
    'Category name': 'Toifa nomi',
    'Ex. Consumables': 'Xarajatlar',
    Brands: "Brendlar",
    Brand: "Brend",
    Suppliers: "Ta’minotchilar",
    Supplier: "Ta’minotchi",
    Subject: 'Mavzu',
    Body: 'Xabar',
    Company: "Kompaniya",
    'Appointment time': 'Tayinlash vaqti',
    'Appointment date': 'Tayinlash sanasi',
    'Make default': 'Odatiy qilib belgilang',
    'Appointment notification template': "Uchrashuv to'g'risida bildirishnoma shabloni",
    'Debt return notification template': "Qarzni to'lash haqida xabarnoma shabloni",
    Services: "Xizmatlar",
    'Services & prices': 'Xizmatlar va narxlar',
    Profile: "Profil",
    profile: "profil",
    Team: "Jamoa",
    "ICD-10": "KXT-10",
    Reports: "Hisobotlar",
    Summary: "Asosiy",
    Payments: "To'lovlar",
    People: "Odamlar",
    'New customer': "Yangi mijoz",
    'New appointment': "Yangi uchrashuv",
    Appointment: 'Uchrashuv',
    Confirmed: "Tasdiqlangan",
    'Not confirmed': "Tasdiqlanmadi",
    confirmed: "tasdiqlangan",
    Completed: "Tugallandi",
    completed: "Tugallandi",
    Complete: "Tugallash",
    'No show': "Kelmadi",
    'noshow': "Kelmadi",
    Today: 'Bugun',
    Tomorrow: 'Ertaga',
    'Next week': 'Kelasi hafta',
    'Next month': 'Keyingi oy',
    Yesterday: 'Kecha',
    'This week': 'Bu hafta',
    'This month': 'Bu oy',
    'Previous week': "Oldingi hafta",
    'Previous month': "Oldingi oy",
    'Custom range': "Diapazon tanlash",
    'By date': "Sana bo'yicha",
    'Team member': "Jamoa a'zosi",
    'By guarantee': 'Kafolat bilan',
    guarantee: 'Kafolat',
    Guarantee: 'Kafolat',
    cash: "Naqd pul",
    credit: "Qarz",
    card: "Karta",
    userBalance: 'Mijoz balansi',
    mobilePayment: 'Mobil to\'lov',
    Presets: 'Tayyor shablonlar',
    Preset: 'Tayyor shablon',
    'New balance': 'Yangi qoldiq',
    'Debt info': 'Qarzni qaytarish',
    'Preset has been updated': 'Shablon yangilandi',
    'Preset has been created': "Shablon qo'shildi",
    bank: "Bank",
    Address: "Manzil",
    "First name": "Ism",
    "Last name": "Familiya",
    "Patient first name": "Bemor ismi",
    "Patient last name": "Bemor familiyasi",
    "Patient middle name": "Bemor otasining ismi",
    Details: "Tafsilotlar",
    General: "Umumiy",
    Gender: "Jins",
    Male: "Erkak",
    Female: "Ayol",
    male: "Erkak",
    female: "Ayol",
    More: "Batafsil",
    Birthdate: "Tug'ilgan sana",
    Phone: 'Telephone',
    Complaints: 'Shikoyatlar',
    Complaint: 'Shikoyatlar',
    Objectively: "Ob'ektiv ",
    Exam: "Ko'rik",
    Exams: "Ko'riklar",
    'New exam': "Yangi ko'rik",
    'Customer card': 'Mijoz kartasi',
    'All exams history will be here': "Barcha ko'riklar shu bo'limda saqlanadi",
    'Date created': 'Kiritilgan sana',
    'Customer passport': "Passport",
    'Nothing yet': "Hech narsa yo'q",
    Code: 'Kod',
    Shortcode: 'Qisqa kodi',
    Description: "Ta'rif",
    Language: 'Til',
    Label: "Nomi",
    manage: "Boshqarish",
    'Complaints DB': 'Shikoyatlar bazasi',
    'Complaint has been saved': 'Shikoyat saqlandi',
    'Complaint has been created': "Shikoyat qo'shildi",
    'Objectively DB': "Ob'ektiv ko'riglar ",
    References: "ma'lumot",
    Edit: "O'zgartirish",
    Timeline: "Tarixi",
    Notes: "Izohlar",
    Billing: "Hisob-kitob",
    Storage: "Fayllar",
    Search: "Izlash",
    Name: "Ism",
    Diagnosis: "Tashxis",
    Diagnoses: "Tashxislar",
    Treatment: "Muolajalar",
    Consumables: 'Sarf materiallari',
    Service: 'Xizmar',
    Price: 'Narx',
    'Retail price': 'Chakana narxi',
    'Retail price per': 'Chakana  narxi (qismi)',
    'Duration (Min)': 'Davomiyligi (min)',
    'Buffer time (Min)': "Servis vaqti",
    'Ex.: A001': 'Masalan.: A001',
    'Treatment name': 'Muolaja nomi',
    'Treatment duration': 'Muolaja davomiyligi',
    'Time required to prepare to the next treatment': "Keyingi muolajaga tayyorlanish uchun zarur bo'lgan vaqt",
    'Ex.: Primary consultation': "Masalan: Shifokorning Birlamchi ko'rigi",
    'Short description of treatment': 'Muolajaning qisqa tasnifi',
    Cancel: 'Bekor qilish',
    Save: 'Saqlash',
    Delete: "O'chirish",
    Ok: 'Ok',
    'Public treatment': "Ommabom muolaja",
    'Public treatments will be published': "Muolaja kalendarda ko'rsatiladi",
    'Services & treatments': 'Xizmatlar va Muolajalar',
    'Loading...': 'Yuklanmoqda...',
    Create: 'Yaratish',
    Duration: 'Davomiyligi',
    'New treatment': 'Yangi muolaja',
    min: 'min.',
    minutes: 'min.',
    h: 's.',
    Upcoming: 'Kelgusi',
    Previous: 'Avvalgi',
    All: 'Barchasi',
    New: 'Yangi',
    cancelled: 'Bekor qilindi',
    Cancelled: 'Bekor qilindi',
    Started: 'Boshlandi',
    started: 'Boshlandi',
    'Total scheduled': 'Jami rejalashtirilgan',
    'Start at': 'Boshlanish vaqti',
    'Appointment at': 'Uchrashuv vaqti',
    Status: 'Holat',
    Staff: 'Xodimlar',
    Active: 'Faol',
    Archived: 'Arxivlangan',
    Age: 'Yoshi',
    Image: 'Rasmi',
    Date: 'Sana',
    'No confirmed appointments': "Tasdiqlangan muolajalar yo'q",
    'No waiting appointments': "Kutayotgan muolajalar yo'q",
    'No waiting online appointments': 'Tasdiqlanmagan online qabul so\'rovlari yo\'q',
    'No started appointments': "Boshlangan muolajalar yo'q",
    'In treatment': 'Muolajada',
    'Drag and drop to upload content!': 'Faylni Tortib tashlang ',
    '...or click to select a file from your computer': '... yoki bosib toping',
    '...or press CTRL + V to paste from clipboard':'... yoki CTRL + V ni bosing',
    'Ex.:John': 'Masalan: Anvarjon',
    'Ex.:Doe': 'Masalan:Dadajonov',
    'Select date': 'Sanani tanlang',
    'Set photo': 'Rasm',
    'Remove image': "Rasmni o'chirish",
    'Blacklist customer': "Qora ro'yhatga",
    Blacklisted: "Qora ro'yhatda",
    'Blacklisted users can not make new appointment. But administrator still can make an appointment.': "Tez-tez muolaja qoldiradigan yoki ishonchsiz ro'yhatiga qoshing",
    'Enable general SMS notifications': 'Umumiy SMS xabarlarni yoqish',
    'If active, the customer agreed to receive general SMS messages': "Agar faol bo'lsa, mijoz umumiy SMS-xabarlarni olishga rozi bo'ldi",
    'Enable promo SMS notifications': 'Promo SMS xabarnomalarini yoqish',
    'If active, the customer agreed to receive promo SMS messages': "Agar faol bo'lsa, mijoz reklama SMS-xabarlarini olishga rozi bo'ldi",
    Add: "Qo'shish",
    'Yes': 'Ha',
    'Add supply': "Ta'minot qo'shing",
    'Add product': "Mahsulot qo'shing",
    'Add category': "Kategoriya qo'shish",
    'Add brand': "Brend qo'shing",
    'Add supplier': "Yetkazib beruvchi qo'shing",
    'Ex.: Caries': "Masalan: Karies",
    Select: "Tanlang",
    'Select diagnosis': 'Tashxisni tanlang',
    'Preliminary diagnosis': 'Dastlabki tashxis',
    'Final diagnosis': 'Yakuniy tashxis',
    'Select from templates': 'Shablonlardan tanlang',
    'Save as template': 'Shablon sifatida tanlang',
    Use: 'Foydalanish',
    'Service name': 'Xizmat nomi',
    'Ex.: Consultation': 'Masalan: Maslahat',
    'Describe service': 'Xizmatni tavsiflang',
    'Public section': 'Xammabop xizmat',
    'Public sections will be published.': 'Xammabop xizmatlarga yozilish mumkin',
    Rank: 'Tartib',
    'Ex.: 0-10': 'Masaln.:0-10',
    Visits: 'Tashriflar',
    'No shows': 'Kelmagan',
    Options: 'Tanlovlar',
    Objectivly: "Ob'ektiv",
    'Are you sure?': 'Ishonchingiz komilmi?',
    'Do you really want to delete the record ?': "Siz haqiqatdan ham o'chirmoqchimisiz?",
    Specialty: 'Mutaxassisligi',
    'Ex.: Therapist': 'Masalan.: Terapevt',
    'Ex.: therapist': 'Масалан.: Terapevt',
    Map: 'Harita',
    Missing: 'Yoq',
    Root: 'Ildiz',
    Caries: 'Karies',
    Pulpit: 'Pulpit',
    Periodontit: 'Periodontit',
    Plombed: 'Plomba',
    Parodontoz: 'Parodontoz',
    Shakiness: 'Qimirlaydi',
    Crown: 'Toj (koronka)',
    Implant: 'Implant',
    'Ortopedical status': 'Ortopedik holat',
    'Therapeutic status': 'Terapevtik holat',
    'Anchor pin': 'Anker shtift',
    Vinir: 'Vinit',
    Retreat: 'Qayta davolash',
    Exclude: "Sug'urish",
    Inset: 'Ichki joy',
    Facet: 'Faset',
    'Parodontal status': 'Parodontal holat',
    'Treatment required': 'Davolash talab etiladi',
    'Treatment not required': 'Davolash talab etilmaydi',
    'Ex. Apple': 'Masalan: Olma',
    Bundles: "Bog'lamlar",
    Bundle: "Bo'glam",
    'Exam created': "Ko'rik o'tkazildi",
    Featured: 'Tanlangan',
    'Treatment plan': 'Davolash rejasi',
    'Select treatment': 'Muolaja tanlang',
    'Add treatment': "Muolaja qo'shish",
    'Tooth #': 'Tish #',
    'Total': 'Jami',
    Channels: 'Kanallar',
    'Time': 'Vaqt',
    'mSv': 'mkzv',
    'No channel measurement': 'Tish kanallari olchanmagan',
    'Includes services or products': 'Xizmatlar va maxsulotlar bilan birga',
    'Print': 'Chop etish',
    Contract: 'Shartnoma',
    Others: 'Boshqalar',
    Agreement: 'Kelishuv',
    'Add item': "Element qo'shish",
    'No slots': "Bo'sh joy yo'q",
    Dates: 'Sanalar',
    Intraoral: 'Intraoral',
    intraoral: 'Intraoral',
    Orthopantomogram: 'Ortropontomogramma',
    orthopantomogram: 'Ortropontomogramma',
    'CT scan': 'KT',
    'cT scan': 'KT',
    'Client application': 'TR-Client application',
    'Company EIN': 'QTUT bo\'yicha muassasa kodi',
    'Add service': 'Добавить сервис',
    'Add another appointment': 'Добавить новую дату',
    'service time': 'сервисное время',
    Xrays: 'Rentgen',
    Xray: 'Rengtgen',
    'New xray': 'Yangi rentgen',
    Dose: 'Doza miqdori',
    Quantity: 'Soni',
    Type: 'Turi',
    'Record created': "Yozuv qo'shildi",
    'Record updated': 'Yozuv saqlandi',
    'Xray created': "Rentgen qo'shildi",
    'Invoice created': 'Xisob yaratildi',
    'Ex.: Lc': 'Masalan.: Lc',
    'Health card': "Sog'lik varaqasi",
    Stock: 'Ombor',
    Supply: "Ta'minot",
    Supplies: "Ta'minotlar",
    Pricing: 'Narxlad',
    'Supply price': 'Kirim narxi',
    'Enable product pricing': 'Narx',
    'Enable stock management': 'Ombor',
    'Enable supply management': "Ta'minot",
    'Product name': 'Maxsulot nomi',
    'Ex.:Medical glove': "Masalan: Tibbiy qo'lqop",
    'Barcode': 'Barkod/Shtrixkod',
    'SKU': 'Artikul',
    'Ex.:12345678': 'Masalan.:12345678',
    'Ex.:AC12345': 'Masalan.:AC12345',
    'Supply date': 'Yetkazib kelingan sana',
    'Expire date': 'Yaroqlilik sanasi',
    'Use current supply': "Joriy ta'minotdan foydalaning",
    'Supply quantity': 'Кол-во в поставке',
    'The supply will be forced to use in checkout and reports': "Barcha xarajatlar joriy ta'minotdan xisoblanadi",
    'Required minimum at store': 'Omborda kerak bolgan qoldiq',
    'Measurement': "O'lchov birlig",
    'Supplier details': "Ta'minotchi ma'lumotlar",
    'Supplier name': "Ta'minotch ismi",
    'Ex. Main supplier': "Masalan: Asosiy ta'minotchi",
    'Supplier notes': "Ta'minotch izohlari",
    'Ex.: Call after 10 AM': "Masalan:Faqat ertalabki 10 day key qo'ng'iroq qiling",
    'Contacts': 'Aloqalar',
    'Contact name': "Bog'lanish uchun shahs",
    'Ex. John Park': 'Masalan.: Omadjon Abdurahmonov',
    'Primary phone': 'Asosiy telefon',
    'Secondary phone': 'Yordamchi telefon',
    'Security': "Havfsizlik",
    'Schedule': "Ish soatlari",
    'Website': 'Website',
    'Street': "Ko'cha",
    'City': "Shahar",
    'State': 'Rayon',
    'ZIP / Post code': "Pochta indeksi",
    'Country': "Mamlakat",
    'Ex.: Tashkent': 'Masalan. Andijon',
    'Billing details': 'Billing tafsilotlar',
    'Legal information': 'Yuridik tafsilotlar',
    'Legal information provided in this section will be used for all your invoices and other legal documents': "Ushbu bo'limda ko'rsatilgan ma'lumotlar yuridik/moliyaviy xujjatlarda foydalaniladi ",
    'Legal name': 'Yuridik nomi',
    'Enter notes for all invoices': 'Xisoblar uchun eslatma',
    'Company timezone': 'Vaqt zonasi',
    'It is important to select correct timezone.': "Tog'ri vaqt zonasini tanlash muxim",
    'Interface default language': 'Standart til',
    'Users can change in their profile.': "Til keynchalik foydalanuvchi tomonidan o'zgartirilish mumkin",
    'Currency': 'Valyuta',
    'For all services calculations': 'Barcha xisob-kitoblar uchun foydalaniladi',
    'Contact information': "Bog'lanish uchun ma'lumot",
    'Social networks': 'Social Tarmoqlar',

    'Ml': 'Ml.',
    'ml': 'ml.',
    'Mg': 'Mg.',
    'mg': 'Mg.',
    'Sm': 'Sm.',
    'Units': 'Dona',
    'units': 'dona',
    Pack: 'Qadoq',
    pack: 'Qadoq',

    'Ex.:100': 'Masalan:100',
    'Minimum': 'Minimum',
    'Per serving': 'Qism',
    'To buy': 'Sotib olish uchun',
    'product': {
        'lowStock': '<b>{product.label}</b> omborda tugab bormoqda'
    },
    'No new notifications': "Yangi xabarnomalar yo'q",
    'In waiting list': "Kutish ro'yhatida",
    'Waitlist': "Kutish ro'yhati",
    'In buy list': "Sotib olish ro'yhatida",
    'Ex.: Agreement': "Masalan.:Shartnoma",
    'Documents': 'Xujjatlar',
    'Title': 'Sarlavxa',
    'Content': 'Tarkib',
    'Source': 'Manba',
    'Sources': 'Manbalar',
    'Campaign': 'Kampaniya',
    'Referer': "Kim yo'naltirgan",
    'Requires value': 'Talab qilinadi',
    'Media': 'Photo',
    'Media manager': 'Media',
    'Treatments': 'Muolajalar',
    'Take a photo': 'Suratga tushirish',
    'Select Device': 'Qurilma tanlash',
    'Logout': 'Chiqish',
    'Leave a comment': 'Izoh qoldirish',
    'Save note': "Izohni saqlash",
    'Password': 'Parol',
    'Current password': 'Xozirgi parol',
    'New password': 'Yangi parol',
    'Confirm new password': 'Parolni tasdiqlang',
    'Update password': 'Parolni yangilang',
    'Password suggestions': 'Parol uchun takliflar',
    'To create a new strong password, you have to meet all of the following requirements:': "Mukammal parol quyidagilardan iborat bo'lishi kerak:",
    'Minimum 8 character': 'Kamida 8 belgi',
    'At least one special character (#%.,)': 'Kamida 1 dona maxsus belgi (#%.,)',
    'At least one number': 'Kamida bitta raqam',
    'Can’t be the same as a previous password': "Avval foydalanilmagan bo'lishi shart",
    'Update schedule': "Jadvalni o'zgartirish",
    Monday: 'Dushanba',
    Tuesday: 'Seshanba',
    Wednesday: 'Chorshanba',
    Thursday: 'Payshanba',
    Friday: 'Juma',
    Saturday: 'Shanba',
    Sunday: 'Yakshanba',
    Notifications: 'Xabarlar',
    'View all': "Hammasini ko'rish",
    'Search client': "Izlash",
    'Appointment schedule': 'Yangi uchrashuv',
    Repeat: 'Takroriy',
    Frequency: 'Har',
    Ends: 'Tugaydi',
    'After {value} times': ' {value}martdan keyin',
    Repeats: 'Takrorlanadi',
    'Every {value} days': 'Har {value} kun',
    'Every 5 days': 'Har 5 kun',
    Weekly: 'Haftada bir',
    'Every {value} weeks': 'Har {value} hafta',
    Monthly: 'Oyda bir marta',
    'Every {value} months': 'Har {value} oy',
    'Every 2 months': 'Har 2 oy',
    'Every 3 months': 'Har 3 oy',
    'Every 4 months': 'har 4 oy',
    'Month': 'Oy',
    Yearly: 'Yilda bir marta',
    'Do not repeat': 'Takrorlanmaydi',
    Daily: 'Har kuni',
    'Specific date': 'Sana',
    Ongoing: 'Hamma vaqt',
    'View Appointment': "Ko'rish",
    Close: 'Yopish',
    Checkout: 'Xisob',
    'Payment method': "To'lov turi",
    'Payment amount': "To'lov summasi",
    'Amount': "Summa",
    'Balance': 'Balans',
    'Payment successful': "To'lov o'tkazildi",
    'Cancel appointment?': 'Отменить запись?',
    Socials: 'Ijtimoiy',
    Settings: 'Sozlamalar',
    Builder: 'Konfigurator',
    'General Details': 'Asosiy tafsilotlar',
    'Company name': "Kompaniya nomi",
    'Your company brand/known name.': 'Brendingiz yoki kompaniyangiz',
    'Ex.:ProfiDental': 'Masalan: ProfiDental',
    'Company description': 'Kompaniya tavsifi',
    'Describe your company in few words so no one wants to read long text.': "O'zingizning kompaniyangizni bir necha so'z bilan ta'riflang",
    'Ex.: We are experts in dental care': "Masalan: Biz tish tuzatishda eng ilg'or kompaniyamiz",
    'Public company': 'Ommaviy kompaniya',
    'Making your profile public means that anyone on the 32Desk network will be able to find you': 'Ommaviy kompaniyalar 32Desk katalogida ishtirok etadi',
    'No payments yet': "To'lovlar yo'q",
    'Access': 'Ruxsat',
    'Payment methods': "To'lov usullari",
    'Cash': 'Naqd pul',
    'Bank': 'Bank orqali',
    'Card': 'Karta ',
    'Credit': 'Qarzga',
    'Other': 'Boshqa',
    'Invoice': 'Hisob',
    'Invoice #': 'Hisob #',
    'Invoiced from': 'Hisob beruvchi',
    'Invoiced ID': 'Hisob tartib raqami',
    'Invoiced to': 'Hisob oluvchi',
    'Cost': 'Narxi',
    'from': 'dan',
    'Total amount due': "To'lov uchun jami",
    'Set password': "Yangi parol",
    'Username': 'Foydalanuvchi login',
    'Role': 'Rol',
    Doctor: 'Doktor',
    Administrator: 'Administrator',
    Director: 'Boshliq',
    'Finance manager': 'Moliya menejeri',
    'Item has been saved': 'Element yozib qolindi',
    'Item has been created': 'Element yaratildi',
    'Hide past': "Faqat kelasi",
    Color: 'Rang',
    Manipulations: 'Manipulyatsiyalar',
    Manipulation: 'Manipulyatsiya',
    'Ex.: Anesthesia': 'Masalan: Anesteziya',
    'Select manipulations': 'Manipulyatsiya tanla',
    'Add manipulation': "Manipulyatsiya qo'shish",
    'Please select a customer': "Foydalanuvchini tanlang",
    'Week': 'Hafta',
    'Day': 'Kun',
    'Days': 'Kunlar',
    'Hours': 'Soat',
    'Template': 'Shablom',
    'Time templates': 'Vaqt shabloni',
    'New template': 'Yangi shablon',
    'Add time template': "Vaqt shablonini qo'shish",
    'Minutes': 'Daqiqa',
    '3 Days': '3 kun',
    'mins ahead': ' | daqiqa |  {n} daqiqa avval |  {n} daqiqa avval',
    'hours ahead': ' | {n} soat avval |  {n} soat avval |  {n} soat avval',
    'days ahead': ' |  {n} kun avval | {n}  kun avval |  {n}  kun avval',
    'weeks ahead': ' |  {n} hafta avval |  {n} hafta avval |  {n} hafta avval',
    'months ahead': ' |  {n} oy avval |  {n} oy avval |  {n} avval',
    'Notify client': 'Mijozga xabar yetkazish',
    'Note created': 'Eslatma saqlandi',
    Browse: 'Tanlash',
    'Please select final diagnonsis': 'Yakuniy tashxisni tanlang',
    'Sign in': 'Kirish',
    'Access to your dashboard': 'Sizning kabitezga kiring',
    'Forgot password?': 'Parolni unutdingizmi?',
    'Enter your password': 'Kabinetingiz paroli',
    'Logging out': 'Chiqish',
    'See you soon': 'Sizni kutib qolamiz',
    'Done': 'Qilindi',
    'User Activities': 'Faoliyat',
    'Value': 'Qiymat',
    // 'N/A': '-',
    'Select diagnosis from ICD-10 list': 'Tashxisni tanlang',
    'To Be Done': 'Tugatish kerak',
    Action: 'Harakat',
    'Add payment': "To'lov qo'shish",
    'Cancellation reason. Ex.: Wrong payment': "Bekor qilish maqsadi. Masalan: Noto'g'ri to'lov",
    'Ex.: Treatment of caries': 'Masalan: Kariesni davollash',
    'Payment cancellation': "To'lovni bekor qilish",
    'Payment cancelled': "To'lovni bekor qilindi",
    'Total paid': "Jami to'lov",
    'Approved': 'Tasdiqlangan',
    'Explanation': 'Izoh',
    'User Balance': 'Mijoz balansi',
    'Debtors': 'Qarzdorlar',
    'Outdated': "Vaqtidan o'tgan",
    'Tags': 'Taglar',
    'Payment status': "To'lov xolati",
    'Payment from balance': "Xisobdan to'lov",
    'Debt details': "Qarzdorlik xaqida",
    'Total Payment': "Ja'mi to'lov",
    'Paid amount': "To'landi",
    'By gender': "Jinsi bo'yicha",
    Income: 'Kirim',
    'Invalid login': "Login yoki parol notug'ri",
    'Average Bill': "O'rtacha xisob",
    'Missing credentials': 'Login va parolni kiriting',
    'New team member created': 'Yangi foydalanuvchi saqlandi',
    'Team member updated': 'Foydalanuvchi ma\'lumotlari yangilandi',
    'Team member deleted': 'Foydalanuvchi o\'chirib yuborildi',
    Specialties: 'Mutaxassisliklar',
    'Static price': 'Doimiy narx',
    'Static duration': 'Doimiy davomiylik',
    'Allow log in': 'Kirishga ruxsat',

    'Your access has been restricted. Please contact your administrator to resolve this issue.': 'Sizning akkautinngiz chegaralangan.',
    Roles: 'Rollar',
    'Balance top up': "Balans to'ldirish",
    Resource: 'Resurs',
    Permissions: 'Ruxsat etilgan',
    'Paid partially': "To'liq to'lanmadi",
    'Paid': "To'landi",
    'New role': "Yangi rol",
    View: "Ko'rish",
    'Edit role': "Rolni o'zgartirish",
    'new': "Yangi",
    'By payment type': "To'lov usuli bo'yicha",
    'By team members': "Jamoa a'zosi ",
    'By age': "Yoshi bo'yicha",
    'Ex.:Sales manager': "Masalan: Menejer",
    'Ex.:Can approve payments and manage billing': 'Masalan: Moliya va Pul masalalarini xal eta oladi',
    'my-team': 'Jamoa',
    'By day': "Kun bo'yicha",
    'By status': "Status bo'yicha",
    'Debt and prepaid': "Qarz va oldindan to'lov",
    'my-company': "Kompaniya",
    'permissions': "Ruxsat etilganlar",
    'Most valuable team member': "Eng ko'p sotgan jamoa a'zosi",
    'Least valuable team member': "Eng kam sotgan jamoa a'zosi",
    'Most valuable patient': "Eng ko'p to'lagan mijoz",
    'Least valuable patient': "Eng kam to'lagan mijoz",
    'Most debt patient': "Qarzodrligi eng ko'p mijoz",
    'Most prepaid patient': "Qarzdorligi eng kam mijoz",
    'Toppers': "Eng ko'p",
    'Most selling product': "Ko'p sotilgan maxsulot",
    'Least selling product': 'Kam sotilgan mijoz',
    'Most used product': "Ko'p ishlatilgan maxsulot",
    'Most used service': "Ko'p qo'llanilgan muolaja",
    'Most selling service': "Ko'p sotilga muolaja",
    'Least used product': "Kam sotilgan maxsulot",
    'Least selling service': "Kam sotilgan muolaja",
    'Least used service': "Kam qo'llanilgan muolaja",
    'roles': "Rollar",
    'users': "Foydalanuvchilar",
    'Add new role': "Rol qo'shish",
    'Actions': "Amallar",
    'See all': "Barchasini ko'rish",
    'Changes to roles saved': "Rollardagi o'zgarishlar saqlandi",
    'Appointment created': "Uchrashuv qo'shildi",
    'Treatment presets': "Davollash shablonlari",
    'SMS template': "Sms xabar shabloni",
    'Explanation of debt': "Izoh",
    'Discount': "Chegirma",
    'Ex.: VIP': "Masalan VIP mijoz",
    'Exam on': "Kor'rik sanasi",
    'Done on': "Bajarildi",
    'Per page': "Bir sahifaga",
    'Tooth condition': "Tish holati",
    'Teeth and mouth condition': "Tish va o'giz bo'shlig'i holati  ",
    'Passport number': 'Pasport seriyasi va raqami',
    'Passport given by': 'Kim tomonidan berilgan',
    'Patient passport number': 'Mijoz pasport raqami',
    'Patient passport given by': 'Mijoz passporti berilga',
    'Patient age': "Mijoz Yo'shi",
    'Patient gender': 'Mijoz jinsi',
    'Patient diagnosis': "Mijoz tashxisi",
    'Patient address': "Mijoz adresi",
    'Patient birth date': "Mijoz tug'ilgan sanasi",
    Earnings: 'Ishlandi',
    Revenue: 'Daromad',
    'Payed out': "To'landi",
    Earned: 'Ishlandi',
    'Left over': 'Qoldiq',
    'Doctor first name': 'Shifokor ismi',
    'Doctor last name': 'Shifokor familiyasi',

    Prepaid: "Oldindan to'lov",
    Channel: "Kanal",
    'Transaction ID': "To'lov № ",
    'Tooth does not have any exams yet': "Tish ko'rikdan o'tmagan",
    'Start examing tooth by clicking "New exam"': "Birinchi ko'rikni boshlang",
    'Conclusion': "Xulosa",
    'Application form': '043 Anketasi',
    Sum: 'Summa',
    Debt: 'Qarz',
    'Emergency logout': 'Tezkor chiqish',
    'Are you sure you want to logout all of your team?': 'Barcha foydalanuvchilarni chiqarib yubormoqchimisiz?',
    'appointment-perm': "Uchrashuvlar",
    'customers-perm': "Mijozlar",
    'my-company-perm': "Mening kompaniyam",
    'my-team-perm': "Mening jamoam",
    'cart-perm': "Savatcha",
    'company-perm': "Kompaniya",
    'profile-perm': "Profil",
    'presets-perm': "Muolaja Shabblonlari",
    'payments-perm': "To'lovlar",
    'products-perm': "Maxsulotlar va ombor",
    'reports-perm': "Hisobotlar",
    'notifications-perm': "Xabarlar",
    'roles-perm': 'Роли и права доступа',
    'treatment-perm': 'Услуги и процедуры',
    'timeline-perm': 'Лог действий',
    'settings-perm': 'Настройки',
    'time-templates-perm': 'Шаблоны времени уведомлений',
    'force-logout-perm': 'Экстренный выход',
    'Pay another': 'Оплатить еще',
    'Back': 'Назад',
    'Pay': "Оплатить",
    'Payment': "Оплата",
    'Pay back': 'Сдачи',
    'Ex.: Pain': 'Прим. Боль',
    'Invoice closed': 'Счет закрыт',
    'Baby map': 'Детская карта',
    'Treatment price': 'Цена процедуры',
    'My 32Desk': 'Мой 32Desk',

    'Current and previous illnes': 'Пересенные и сопутсвующие заболевания',
    'Profession': 'Профессия',
    'X-ray examinations history': 'История рентгенологических обследований',
    'Document generated by': 'Сгенерировано при помощи',
    'Manipulations made': 'Проделанные манипуляции',
    'Products used': 'Использованные продукты',
    'Ministry of Healthcare': 'Министерство здравоохранения',
    'ProfileFormBy': 'Медицинская документация форма № 043/У    <br />Утверждена Уз.РССВ 10.05.2010',
    'Medical card of dental patient': 'Медицинская карта стоматологического больного',
    'Fullname': 'Фамилия, Имя, Отчество',

    'Recommendations made': 'Даны рекомендации',
    'Recommendations': 'Рекомендации',
    'Ex. rinse mouth twice a day': 'Прим. Полоскать рот два раза в день',
    'Elementary examination': 'Первичный осмотр',
    'Bite': 'Прикус',
    'Overjet': 'Глубокий',
    'Open bite': 'Открытый',
    'Crossbite': 'Перекресный',
    'Overbite': 'Дистальный (прогнатический)',
    'Underbite': 'Мезиальный (прогенический)',

    'Orthognathic': 'Ортогнатический',
    'Progenic': 'Прогенический',
    'Straight': 'Прямой',
    'Biprognatic': 'Бипрогнатический',
    'State of oral mucosa': 'Состояние слизистой  оболочки полости рта, десен, альвеолярных отростков и неба',
    'X-Ray and Lab examination results': 'Данные рентгеновских и лабораторных исследований',
    'Normal': 'Хороший ',
    'Satisfactory': 'Удовлетворительный',
    'Unsatisfactory': 'Неудовлетворительный',
    'Bad': 'Плохой',
    'Very Bad': 'Очень плохой',
    'Accept appointments': 'Принимает записи',
    'Percent from total revenue': 'Базовый процент от услуг',
    'By Staff': 'По сотрудникам',

    'Scheduled for today': 'Запланировано на сегодня',
    'Oral Hygiene Index': 'Индекс Гигиены',
    'Unknown': 'Неивестный',
    'POS': 'Точка продаж',
    'POS terminal': 'Терминал продаж',
    'Duplicate to tooth': 'Скопировать на зуб',
    'All done': 'Все сделано',
    'Apply': 'Применить',
    'All top': 'Все верхние',
    'All bottom': 'Все нижние',
    'Clear': 'Очистить',
    'By tooth': 'По зубам',
    'Remove': 'Удалить',
    'Appointment has been rescheduled': 'Запись изменена',
    'Do you confirm ?': 'Вы подтверждаете ?',
    'phone-field': {
        'required': 'Majburiy maydon'
    },
    'customer-field':{
        'required': 'Majburiy maydon'
    },
    'invoice': {
        'print-preview': 'Vaqtinchalik hisob',
        'preview-to': 'Vaqtinchalik hisob oluvchi',
        'not-paid': "To'lanmagan"
    },
    'Сombine invoices': 'Hisoblarni birlashtirish',
    'payment': {
        'status': {
            'debiting-from-balance': "Hisobdan yechib olish"
        }
    },
    'communications': {
        'failed': 'Jo\'natilmadi',
        'scheduled': 'Qoldirildi',
        'sent': 'Jo\'natildi'
    },
    // TODO: 'Subscription' from SubscriptionList.vue,
    'Oral cavity': 'Og\'iz bo\'shlig\'i',
    'tasks': {
        'task-type': 'Vazifa turi',
        'treatment-reminder': 'Davolash haqida eslatma',
        'user-reminder': 'Eslatma',
        'task-description': 'Vazifa tavsifi',
        'desc-placeholder': 'Buxgalterga qo\'ng\'iroq qilish'
    },
    // TODO: 'Subscription' from SubscriptionList.vue,
    'Show online appointments': 'Online qabullarni ko\'rsatish',
    'Drafts': 'Qoralama qabullar',
    'filters': {
        'by-completion-date': "Topshirish sanasi bo'yicha",
    },
    // TODO: 'Subscription' from SubscriptionList.vue,
    'payments': {
        'total-paid': 'Jami Tushum',
        'total-services': 'Jami Xizmatlar',
        'total-discount': 'Jami Chegirmalar',
    },
    'sessions': {
        'sessions': 'Faol qurilmalar',
        'last-action': 'Oxirgi faoliyat',
        'user-agent': 'Brauzer va qurilma',
        'login': 'Oxirgi login',
        'ip': 'IP manzil'
    },
    'perm': {
        'appointment': 'Uchrashuvlar',
        'customers': 'Mijozlar',
        'my-company': 'Mening kompaniyam',
        'my-team': 'Mening jamoam',
        'cart': 'Savat',
        'company': 'Kompaniyalar',
        'profile': 'Profil',
        'presets': 'Davolash andazalari',
        'payments': 'To\'l\'ovlar',
        'tags': 'Teglar',
        'communications': 'Mijoz bilan aloqa',
        'notes': 'Qaydlar',
        'xrays': 'Rentgen',
        'products': 'Mahsulotlar',
        'reports': 'Hisobotlar',
        'notifications': 'Habarnomalar',
        'roles': 'Rollar',
        'treatment': 'Davolash',
        'icd': 'ICD-10',
        'odb': 'Obyektiv',
        'service': 'Xizmatlar',
        'timeline': 'Faoliyat jurnali',
        'bundle': 'To\'plamlar',
        'time-templates': 'Vaqtni andozalari',
        'force-logout': 'Favqullotda chiqish',
        'tasks': 'Vazifalar',
        'work-place': 'Ish o\'rinlari',
        'service-reports': 'Xizmat bo\'yicha hisobotlar',
        'product-reports': 'Mahsulot bo\'yicha hisobotlar',
        'sales-reports': 'Sotilish bo\'yicha hisobotlar',
        'customer-reports': 'Mijoz bo\'yicha hisobotlar',
        'configurator': 'Konfigurator',
        'treatment-plan': 'Davolash rejasi',
        'media-summary': 'Media fayllar bo\'yicha hisobotlar',
        'subscriptions': 'Obunalar',
        'push-notifications': 'Push notifikatsiya',
        'resources': 'Resurslar',
        'text-templates': 'Matnni andozalari',
        'feedback-question': 'Savollar',
        'feedback-questionnaire': 'Savolnomalar',
        'feedback-answers': 'Javablar',
        'labs': 'Laboratoriyalar',
        'expense': 'Chiqimlar',
        'toppers': 'Topdagilar',
        'balance-reports': 'Oldindan to\'lo\'vlar va qarzlar bo\'yicha hisobotlar',
        'payment-reports': 'To\'lov turlari bo\'yicha hisobotlar',
        'appointment-reports': 'Uchrashuvlar bo\'yicha hisobotlar',
        'suppliers': 'Yetkazib beruvchilar',
        'supplies': 'Yetkazmalar',
        'categories': 'Kategoriyalar',
        'brands': 'Brendlar',
        'reports-summary': 'Umumiy hisobotlar',
        'invoices-summary': 'To\'ko\'vlar bo\'yicha hisobotlar',
        'notification-reports': 'Habarnomalar bo\'yicha hisobotlar',
        'sources': 'Manzillar',
        'specialty': 'Mutaxassislik',
        'cdb': 'Shikoyatlar',
        'notification': 'Habarnomalar',
        'my-notifications': 'Mening habarnomalarim',
        'diagnose-objectively': 'Diagnosizalar',
        'fields': 'Salomatlik kartasi',
        'exam': 'Ko\'rib chiqish',
        'manipulations': 'Manipulatsiyalar',
        'users': 'Foydalanuvchilar',
        'permissions': 'Ruxsatlar',
        'tooth': 'Tishlar',
        'invoices': 'To\'lov varaqalari',
        'transactions': 'To\'lovlar',
        'documents': 'Hujjatlar',
        'sms': 'SMS',
        'sessions': 'Aktiv sessiyalar',
        'payment-history': "To'lovlar tarixi hisoboti",
        'expense-reports': "Chiqimlar bo'yicha hisobot",
        'onlinepbx-incoming-call': 'OnlinePBX tizimi',
    },
    'History': "To'lovlar tarixi",
    // TODO: 'Subscription' from SubscriptionList.vue,
    'Treatment select while scheduling an appointment in the calendar': 'Qabulga yozilish jarayonida muolaja talab qilish',
    'Enabled: required, treatment will be included in the invoice': 'Faol: muolaja va servis tanlash talab qilinadi, va ular hisobga yoziladi',
    'Disabled: not required, treatment will not be included in the invoice': 'O\'chirilgan: muolaja va servis tanlash majburiy emas, va ular hisobga yozilmaydi',
    'Technican': 'Texnik',
    'Dental chart': 'Tish formulasi',
    'For example: American, FDI': 'Masalan: AQSH, JSST',
    'FDI': 'JSST',
    'USA': 'AQSH',
    'New initial exam layout': "Yangi boshlang'ich ko'rik interfeysi",
    'Use new initial exam layout': "Yangi boshlang'ich ko'rik interfeysini faollashtirish",
    'x-ray': {
        'ct_up_low_jaw': 'KT i/n/h',
        'ct_up_low_tmj_jaw': "KT skanerlash in/n/h va Temporomandibular bo'g'inlar",
        'ct_up_ms_jaw': 'Kompyuter tomografiyasi maksiller sinuslar',
        'tgr_lateral_projection': 'Teleroentgenogramma lateral proektsiyada',
        'tgr_front_projection': "Teleroentgenogramma to'g'ridan-to'g'ri proektsiyada",
        'tgr_subchinoparietal_projection': 'SMV proyeksiyasida Teleroentgenogramma',
        'section4_tmj': "Temporomandibular bo'g'inlarning 4 qismli tasviri",
        'section6_tmj': "Temporomandibular bo'g'inlarning 6 qismli tasviri",
    },
    'Online appointments': 'Onlayn qabulga so\'rovlar',
    'Appointment booking': 'Qabulga yozish',
    'Online appointment booking': 'Onlayn qabul',
    'Allows your clients to record themselves using a step-by-step form': 'Mijozlaringizga onlayn qabulga yozilish imkoniyatini yaratish',
    'Skip doctor select': 'Shifokor tanlovini o\'tkazib yuborish',
    'Disallows your clients to select the doctor': 'Mijoz online qabulga yozilish davomida shifokorni tanlamaydi',
    'Skip time select': 'Vaqt tanlovini o\'tkazib yuborish',
    'Disallows your clients to select the time for appointment': 'Mijoz online qabul vaqtini belgilamaydi',
    'Skip date select': 'Kun tanlovini o\'tkazib yuborish',
    'Disallows your clients to select the date for appointment': 'Mijoz online qabul kunini belgilamaydi',
    'Your session': 'Sizning sessiyangiz',
    'Mobile payments': "Mobil to'lov",
    'Created by': 'Kim qo’shgan',
    'Values': 'Qiymatlar',
    'Show If:': 'Agar ko\'rsat:',
    'Is equal to:': 'Ga teng bo\'lsa:',
    'Use in profile card:': 'Profil kartasida ko\'rsatish:',
    'Custom field': 'Moslashtirilgan maydon',
    'Subscribe Push Notification': 'Push bildirishnomasiga obuna bo\'ling',
    'Subscription': 'Push Obuna',
    'No subscription yet': 'Hozircha obuna yo‘q',
    'Current subscription': 'Joriy obuna',
    'Created at': 'Qo‘shgan vaqt',
    'Patient health card': 'Bemor salomatligi kartasi',
    'The information you provide will allow the doctor to choose effective anesthesia, medications and methods of treatment, and correctly draw up a treatment plan. The doctor will explain (in justified cases): whether there is a connection between your general health and the state of the oral cavity.': 'Siz taqdim etgan ma\'lumotlar shifokorga samarali davolash usullarini tanlash va davolash rejasini to\'g\'ri tuzish imkonini beradi. Shifokor tushuntiradi: umumiy sog\'ligingiz va og\'iz bo\'shlig\'i holati o\'rtasida bog\'liqlik bormi.',
    'Your data is a medical secret and will not be available to unauthorized persons.': 'Sizning ma\'lumotlaringiz tibbiy sir bo\'lib, ruxsatsiz shaxslarga ochiq bo\'lmaydi.',
    'Last visit to a hospital or clinic (month, year):': 'Kasalxona yoki klinikaga oxirgi tashrif (oy, yil):',
    'What was the appeal about?': 'Murojaat nima haqida edi?',
    'Are you registered in a psycho-neurological, narcological, dermatological and venereal dispensary?': 'Siz psixo-nevrologik, narkologik, teri va venerologik dispanserda ro\'yxatga olinganmisiz?',
    'If yes, in what and from what time?': 'Ha bo\'lsa, qaysi vaqtda va qaysi vaqtda?',
    'In addition, I want to inform you about the state of my health the following:': 'Bundan tashqari, men sizga sog\'lig\'im haqida quyidagilarni ma\'lum qilmoqchiman:',
    'Date of visit': 'Tashrif sanasi',
    'Telegram authentication': 'Telegram autentifikatsiyasi',
    'Enter the phone number to enter the Telegram account from which notifications about recordings will be sent to customers': 'Telegram akkauntiga kirish uchun telefon raqamini kiriting, undan mijozlarga uchrashuvlar haqida bildirishnomalar yuboriladi',
    'Integrations': 'Integratsiyalar',
    'Soon': 'Tez orada',
    'Enter phone number': 'Telefon raqamini kiriting',
    'Send code': 'Kodni yuboring',
    'Telegram code': 'Telegram kodi',
    'Two-factor authentication': 'Ikki faktorli autentifikatsiya',
    'Current list': 'Joriy ro\'yxat',
    'Total size': 'Umumiy hajmi',
    'Now':'Hozir',
    'When scheduled':'Yaratilganda',
    'Upload date': 'Yuklash sanasi',
    'Size': 'Hajmi',
    'Ascending': 'O\'sish bo\'yicha',
    'Descending': 'Kamayish bo\'yicha',
    'Sort by': 'Sortirovka',
    'Ex.: Pain while eating': 'Masalan: Ovqat paytida og\'riq',
    'Already exists': 'Roʻyxatda mavjud',
    'Logged in': 'Joriy akkaunt',
    'Logged out': 'Chiqish',
    'Phone number': 'Telefon raqami',
    'Log in': 'Kirish',
    'Enter the code from SMS or check your telegram account': 'SMSdagi kodni kiriting yoki Telegram akkauntingizni tekshiring',
    'You have enabled cloud password. Please, enter it here': 'Sizda parol o\'rnatilgan. Iltimos, ushbu maydonga kiriting',
    'Invalid phone number': 'Telefon raqami noto\'g\'ri',
    'Invalid code': 'Kod noto\'g\'ri',
    'Invalid password': 'Parol noto\'g\'ri',
    'Too many attempts. Try again later': 'Juda ko\'p urinishlar. Iltimos, kechroq qayta urinib ko\'ring',
    'Telegram integration is not configured': 'Telegram integratsiyasi o\'rnatilmagan',
    'Configure': 'O\'rnatish',
    'Scheduled': 'Rejalashtirilgan',
    'Do not notify': 'Bildirishnoma yuborilmaydi',
    'Add Notes': 'Eslatma qo\'shish',
    'Select country': 'Davlatni tanlang',
    'For example: USA, Finland, etc': 'Masalan: O\'zbekiston, Qozog\'iston va boshqalar',
    'Wrong API key': 'Noto\'g\'ri API kalit',
    'OnlinePBX API key': 'OnlinePBX API kaliti',
    'OnlinePBX domain': 'OnlinePBX domeni',
    'OnlinePBX VoIP service integration': 'OnlinePBX VoIP xizmatini ulash',
    'Integration saved successfully': 'Ulanish muvaffaqiyatli amalga oshirildi',
    'Logged out successfully': 'Chiqish muvaffaqiyatli amalga oshirildi',
    'Domain should end with .onpbx.ru': 'Domen .onpbx.ru ga tugashi kerak',
    'OnlinePBX is not configured': 'OnlinePBXga ulanish amalga oshirilmagan',
    'Waiting clinic to accept the call': 'Shifoxona qo\'ng\iroqni qabul qilishini kutyabmiz',
    'Clinic has not accepted call': 'Shifoxona qo\'ng\'iroqni qabul qilmadi',
    'Call is started': 'Suhbat boshlandi',
    'Call': 'Qo\'ng\'iroq',
    'Current date': 'Joriy sana',
    'examDefaults': {
        'objectivly': 'Yuz nosimmetrik, ko\'rinadigan patologik o\'zgarishlarsiz yuz terisi va ko\'rinadigan shilliq pardalarga mutanosib..',
        'OralMucosa': 'Ko\'zga ko\'rinadigan patologik o\'zgarishlarsiz och pushti shilliq qavat'
    },
    'treatment-plan': {
        'label-placeholder': 'Eslatma: Terapevtik davolash rejasi'
    },
    'settingsDesc': {
        'services': 'Ushbu bo\'limda xizmatlar, protseduralar, ularning narxi va davomiyligini boshqaring',
        'team': 'Bu erda xodimlar to\'g\'risidagi ma\'lumotlar, taqvimdagi yozuvlarning rangi, jadvali, xodimlarning ish haqi sozlangan',
        'company': 'Kompaniyangizni sozlash bo\'limi',
        'documents': 'Mijozlarning nomlarini avtomatik ravishda to\'ldirish bilan shartnomalar va boshqa hujjatlarni yarating va hokazo',
        'sources': 'Mijoz sizning kompaniyangiz haqida qanday bilib oldi, sizga reklama kampaniyalarini tahlil qilish imkonini beradi',
        'tags': 'Teglar mijozlarni filtrlash uchun segmentlarga ajratish, shuningdek, mijozlarni "belgilash" imkonini beradi',
        'work-places': 'Kalendarda qulay ko\'rsatish uchun ish joylarini, xonalarni, ofislarni qo\'shing',
        'roles': 'Moslashuvchan kirish sozlamalari - xodim nima qilishi mumkinligini ko\'rsatib, rollarni yaratish',
        'user-activities': 'Barcha foydalanuvchilar tomonidan amalga oshirilgan harakatlar tarixi',
        'trash': 'Muhim o\'chirilgan elementlar butunlay o\'chirilishidan oldin arxiv savatiga tashlanadi',
        'specialties': 'Kompaniyangiz xodimlarining mutaxassisliklari',
        'icd10': 'Kasalliklarning xalqaro tasnifi (ICD-10)',
        'complaints': 'Bemorlarning shikoyatlari ro\'yxati ambulatoriya kartalarini samarali to\'ldirishga imkon beradi',
        'objectively': 'Ambulator kartalarni samarali to\'ldirish uchun "Obyektiv ravishda ..." ro\'yxati',
        'manipulations': 'Jarayonlar davomida amalga oshirilgan manipulyatsiyalar ambulatoriya kartalarini avtomatik ravishda to\'ldirishga imkon beradi',
        'presets': 'Davolash shablonlari bajarilgan muolajalarni tezda tuzatish, shuningdek, ambulator kartalarni avtomatik ravishda to\'ldirish uchun qulay vositadir.',
        'configurator': 'To\'g\'ri sozlangan konfigurator bir marta bosish bilan davolash rejasini to\'ldirishga imkon beradi',
        'questionnaires': 'Mijoz so\'rovnomalarini boshqarish',
        'textTemplates': 'Siz yangi matn shablonlarini yaratishingiz, tahrirlashingiz, o\'chirishingiz mumkin',
        'sessions': 'Faol seanslar va qurilmalar',
    },
    'treatment': {
        'print': 'Chop etish'
    },
    'Added successfully': 'Muvaffaqiyatli qo\'shildi',
    'Customers with uncompleted work order': 'Ish tartibi tugallanmagan mijozlar',
    'No records for this call': 'Bu qo\'ng\'iroq yozib olinmagan',
    'account_expired': "Sizning akkautinngiz cheklandi. Iltimos, qo'llab-quvvatlash jamoasi bilan bog'laning <a href='https://32desk.com'>32desk.com</a>",
    'Show teeth in invoice': 'Hisobda tishlarni ko\'rsatish',
    'Show \'Tooth\' column in invoice': 'Hisobda \'Tishlar\' ustunini ko\'rsatish',
    'Edit treatment data after work order': 'Anketalarni to\'ldirish',
    'Show additional page on work order to edit done treatments\' data': '043 anketa to\'ldirish uchun maxsus sahifani faollashtirish',
    'Legend': 'Shartli belgilar',
    'DP - dental plaque': 'DP - tish toshi',
    'M - movability': 'M - harakatchanlik',
    'R - root': 'R - ildiz',
    'C - caries': 'C - karies',
    'AT - artificial tooth': 'AT - sun\'iy tish',
    'Cr - crown': 'Cr - toj (koronka)',
    'Pt - periodontitis': 'Pt - periodontit',
    'P - pulpitis': 'P - pulpit',
    'D - depulped': 'D - depulsatsiya qilingan',
    'Pl - plombed': 'Pl - plomba',
    'I - implant': 'I - implant',
    'Note has been deleted': 'Izoh o\'chirib yuborildi',
    'Customer is archived': 'Mijoz arxivga yuborildi',
    'Customer is updated': 'Mijozning ma\'lumotlari yangilandi',
    'Customer is registered': 'Mijoz ro\'yxatga olindi',
    'Print options': 'Chop etish sozlamalari',
    'Hide teeth map': 'Tishlar xaritasini yashirish',
    'Hide remark': 'Izohni yashirish',
    'Size of teeth map': 'Tishlar xaritasini o\'lchami',
    'Adult map': 'Kattalar tish xaritasi',
    'email.subject': '{company}dan xabar',
    'Treatment has been deleted': 'Muolaja o\'chirib yuborildi',

    'Manipulation was removed': 'Manipulasiya o\'chirib yuborilgan',
    'Service was removed': 'Xizmat o\'chirib yuborilgan',
    'Product was removed': 'Mahsulot o\'chirib yuborilgan',
    'Changed': 'O\'zgarish',
    'Interest': 'Stavka',
    'Company code': 'Korxona kodi',
    'Code will be used in profile card of customer': 'Kod bemorning shaxsiy varaqasida ko\'rsatiladi',
    "The description of the treatment in the patient's medical record is not editable": "Bemorning tibbiy kitobidagi davolanish tavsifini tahrirlash mumkin emas",
    'The order includes an additional treatment description page for the patient\'s medical record \'Next\'. The outfit will be considered incomplete if you do not go to this additional page.': "Buyurtma bemorning \"Keyingi\" tibbiy yozuvi uchun qo'shimcha davolash tavsif sahifasini o'z ichiga oladi. Agar siz ushbu qo'shimcha sahifaga kirmasangiz, kiyim to'liq emas deb hisoblanadi.",

    'Top ups': 'Hisob to\'ldirish',
    'Date constraint is required': 'Sana bo\'yicha filtr majburiy',
    'Deleted customer': 'Arxivlangan mijoz',

    'Service language': 'Xizmat ko\'rsatish tili',
    'Default service language': 'Xizmat ko\'rsatish tili',
    'Default service language for new customer': 'Yangi mijozlar uchun xizmat ko\'rsatish tili',

    'Removed user': "O'chirib yuborilgan foydalanuvchi",
    'Language & Region': 'Til va Region',

    'My earnings': 'Mening daromadim',
    'Last withdrawal': 'Oxirgi chiqarish',

    'Code has been sent to the number:': 'Shu telefon raqamga kod yuborildi:',
    'Change number': 'Nomerni o\'zgartirish',
    'Help': 'Yordam',

    'Notification templates': 'Eslatmalar matnlari',

    'Service language allows you to send notifications in the client\'s language': 'Xizmat ko\'rsatish tili mijozga eslatmalarni o\'z tilida jo\'natish imkonini beradi',
    'Service languages': 'Xizmat ko\'rsatish tillari',
    'Add a new service language': 'Yangi xizmat ko\'rsatish tilini qo\'shish',
    'Default': 'Standart',
    'Language added': 'Til qo\'shildi',
    'Language removed': 'Til o\'chirildi',
    'New default language selected': 'Standart til o\'zgartirildi',

    'Debt due date': 'Qarz qaytarish sanasi',
    'Appointment rescheduled notifications': 'Qabul vaqtini o\'zragishi eslatmasi',
    'Notification about reschedule of the appointment': 'Mijozni qabul vaqti o\'zraganligi haqida ogohlantirish',
    'Appointment rescheduled notification template': 'Qabul vaqtini o\'zragishi eslatmasining matni',
    'VAT': 'QQS',
    'incl. VAT': 'sh.j. QQS',
    perm: {
        'customer-phone': 'Mijozning telefon raqami',
    },
    'with VAT': 'QQS bilan',
    'Prices include VAT': 'Narxlar QQS bilan ko\'rsatilgan',

    'Questionnaires': 'So\'rovnomalar',
    'questionnaire': {
        'questions_count': 'Savollar: {n}',
    },
    'questionnaire-question': {
        'types': {
            'text': 'Matn',
            'single-choice': 'Bir tanlov',
            'multiple-choice': 'Bir nechta tanlov',
        }
    },
    'No options yet. Press + to add': 'Variantlar mavjud emas. + tugmasini bosib qo\'shish',
    'Option': 'Variant',
    'Option. Ex. Satisfied': 'Variant. Masalan, Juda yaxshi',
    'New question': 'Yangi savol',
    'Edit question': 'Savolni tahrirlash',
    'New questionnaire': 'Yangi so\'rovnoma',
    'Edit questionnaire': 'So\'rovnomani tahrirlash',
    'Questions count': 'Savollar soni',
    "Question has been created": 'Savol qo\'shildi',
    'Question has been saved': 'Savol saqlandi',
    'Question deleted successfully': 'Savol o\'chirib yuborildi',
    'Questionnaire has been created': 'So\'rovnoma qo\'shildi',
    'Questionnaire has been saved': 'So\'rovnoma saqlandi',
    'Questionnaire has been removed': 'So\'rovnoma o\'chirib yuborildi',
    'No questions yet': 'Savollar yo\'q',
    'Question': 'Savol',
    'Ex.: Rate the service': 'Masalan: Bizning xizmatni qanday baholaysiz?',
    'Answer type': 'Javon turi',
    'Select type': 'Turni tanlang',

    'Send survey link': 'So\'rovnoma havolasini jo\'natish',
    'Copied to clipboard!': 'Buferga nusxalandi!',
    'Surveys': 'So\'rovnoma',
    'Answers': 'Javoblar',
    'answers': 'javoblar',
    'questions': 'savollar',

    'No questionnaires': 'So\'rovnomalar yo\'q',
    'Ex.: Service rate survey': 'Masalan: mijoz anketasi',
    'To': 'Kimga',

    'Total received': 'Ja\'mi tushum',
    'Notification channels': 'Eslatma yuborish usullari',
    'Default notification channels': 'Standart eslatma yuborish usullari',
    'These channels will be enabled for new customers': 'Bu usullar yangi mijozlar qo\'shilganda yoqilgan bo\'ladi',

    'WhatsApp integration': 'WhatsApp bilan integratsiya',
    'Phone number ID': 'Telefon raqami ID',
    'Access token': 'Token',
    'WhatsApp webhook setup params:': 'WhatsApp webhook uchun sozlamalar:',
    'Verify Token': 'Tekshirish tokeni',
    'Whatsapp integration updated': 'Whatsapp integrasiyasi yangilandi',
    'Webhook URL': 'Webhook URL',
    'Whatsapp integration cleared': 'WhatsApp integration o\'chirildi',
    'Enter Whatsapp template name': 'WhatsApp shablon nomini kiriting',

    'Link for Online booking': 'Onlayn yozilish uchun havola',
    'alias: value already exists.': 'Bu nom band',
    'Scheduled to': 'Rejalashtirilgan sana',

    'Reset link sent to your email': 'Parolni tiklash uchun havola pochtangizga yuborildi',
    'Reset Password': 'Parolni tiklash',
    'Enter your email to get a password reset link': 'Parol tiklash havolasi uchun elektron pochtangizni kiriting',
    'Password reset': 'Parolni tiklash',
    'Enter new password': 'Yangi parolni kiriting',
    'Password successfully changed': 'Parol muvaffaqiyatli o\'zgartirildi',
    'Remember your password?': 'Parolingizni esladingizmi?',
    'Invalid email': 'Noto\'g\'ri email',
    'If your email would be found in 32Desk, you will receive instruction to reset password': 'Agar sizning emailingiz 32Desk tizmida topilsa, sizga tiklash uchun yo\'riqnoma yuboriladi',
    'Error occurred': 'Xatu yuz berdi',
    'Go to login': 'Kirish',
    'Email is sent': 'Email yuborildi',
    'Please, try again': 'Iltimos, yana urinib ko\'ring',
    'Password is updated': 'Parol tiklandi',
    'You can login with new password to 32Desk': 'Siz yangi parolingiz bilan 32Deskga kirishingiz mumkin',
    'Please wait, this will take a few seconds': 'Kutib turing, bu jarayon bir necha soniya davom etadi',
    'Retry': 'Qayta urinib ko\'rish',
    'Terminate other sessions': 'Qolgan sessiyalarni yakunlash',
    'Logs out all devices except for this one': 'Bundan tashqari barcha qurilmalarni tizimdan chiqaradi',
    'Terminate all sessions, except current one?': 'Ushbu sessiyadan tashqari barcha qolganlarini yopmoqchimisiz?',
}
