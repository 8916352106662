export default {
    'mins ahead': ' {n} mins ahead',
    'hours ahead': ' {n} hours ahead',
    'days ahead': '  {n} days ahead',
    'weeks ahead': ' {n} weeks  ahead',
    'months ahead': ' {n} months ahead',
    'in days': '| in a day | in {n} days',
    'in weeks': '| in a week | in {n} weeks',
    'in months': '| in a month | in {n} months',
    'phone-field': {
        'required': 'Required field'
    },
    'customer-field': {
        'required': 'Required field'
    },
    'y.o.': '{n} y.o.',
    'conditionCodes': {
        'C': 'C',
        'Pl': 'Pl',
        'D': 'D',
        'P': 'P',
        'Pt': 'Pt',
        'Cr': 'Cr',
        'AT': 'AT',
        'I': 'I',
        'R': 'R',
        'M': 'M',
        'DP': 'DP',
        'TM': 'TM'
    },
    'invoice': {
        'print-preview': 'Provisional invoice',
        'preview-to': 'Provisional invoice for',
        'not-paid': 'Not paid',
        'summary': 'Invoice summary'
    },
    mobilePayment: 'Mobile payment',
    userBalance: 'Customer balance',
    'treatment-plan': {
        'label-placeholder': 'Ex: Therapeutic treatment plan'
    },
    'payment': {
        'status': {
            'debiting-from-balance': 'Debiting from balance'
        }
    },
    'Oral cavity': 'Oral cavity',
    'communications': {
        'failed': 'Failed to send',
        'scheduled': 'Scheduled',
        'sent': 'Sent'
    },
    'tasks': {
        'task-type': 'Task type',
        'treatment-reminder': 'Treatment reminder',
        'user-reminder': 'Reminder',
        'task-description': 'Task description',
        'desc-placeholder': 'Call the accountant'
    },
    'settingsDesc': {
        'services': 'Manage services, procedures, their prices and duration in this section',
        'team': 'Information about employees, color of entries in the calendar, schedule, employee salaries are configured here',
        'company': 'Section on setting up your company',
        'documents': 'Create contracts and other documents with automatic completion of customer names, etc. here',
        'sources': 'How the client found out about your company, allow you to analyze advertising campaigns',
        'tags': 'Tags allow you to segment customers for further filtering by them, as well as "label" customers',
        'work-places': 'Add workplaces, rooms, offices for easy display in the calendar',
        'roles': 'Flexible access settings - create roles, specifying what an employee can do',
        'user-activities': 'History of actions taken for all users',
        'trash': 'Important deleted items end up in the trash before being deleted permanently',
        'specialties': 'Specialties of your company employees',
        'icd10': 'International Classification of Diseases (ICD-10)',
        'complaints': 'The list of patient complaints will allow you to effectively fill out outpatient cards',
        'objectively': 'List "Objectively ..." for effective filling out of outpatient cards',
        'manipulations': 'Manipulations carried out during the procedures allow you to automatically fill out outpatient cards',
        'presets': 'Treatment templates are a convenient tool for quickly recording the performed procedures, as well as for automatically filling out outpatient cards',
        'configurator': 'A properly configured configurator will allow you to fill out a treatment plan with one click',
        'questionnaires': 'Manage questionnaires to collect feedbacks from your customers',
        'textTemplates': 'You can create, edit, delete new text templates',
        'sessions': 'Active sessions and devices',
    },
    'Technican': 'Technician',
    'sessions': {
        'sessions': 'Active sessions',
        'last-action': 'Last action',
        'user-agent': 'Browser and device',
        'login': 'Last login',
        'ip': 'IP address'
    },
    'filters': {
        'by-completion-date': 'By completion date',
    },
    'Technican': 'Technician',
    'Date created': 'Created',
    'payments': {
        'total-paid': 'Total Paid',
        'total-services': 'Total Services',
        'total-discount': 'Total Discount',
    },
    'Caries': 'Caries',
    'Plombed': 'Filling',
    'Depulped': 'Depulped',
    'Pulpitis': 'Pulpitis',
    'Periodontitis': 'Periodontitis',
    'Crown': 'Crown',
    'Artificial tooth': 'Artificial tooth',
    'Implant': 'Implant',
    'Root': 'Root',
    'Dental plaque': 'Dental plaque',
    'Tooth missing': 'Tooth missing',
    perm: {
        'appointment': 'Appointments',
        'customers': 'Customers',
        'my-company': 'My company',
        'my-team': 'My team',
        'cart': 'Cart',
        'company': 'Company',
        'profile': 'Profile',
        'presets': 'Presets',
        'payments': 'Payments',
        'tags': 'Tags',
        'communications': 'Communications',
        'notes': 'Notes',
        'xrays': 'X-rays',
        'products': 'Products',
        'reports': 'Reports',
        'notifications': 'Notifications',
        'roles': 'Roles and access permissions',
        'treatment': 'Treatments',
        'icd': 'ICD',
        'odb': 'Objective diagnosis',
        'service': 'Services',
        'timeline': 'Activity log',
        'bundle': 'Bundles',
        'time-templates': 'Time templates',
        'force-logout': 'Force logout',
        'tasks': 'Tasks',
        'work-place': 'Workplaces',
        'service-reports': 'Service reports',
        'product-reports': 'Product reports',
        'sales-reports': 'Sales reports',
        'customer-reports': 'Customer reports',
        'notification-reports': 'Notification reports',
        'configurator': 'Configurator',
        'treatment-plan': 'Treatment plan',
        'media-summary': 'Media files summary',
        'subscriptions': 'Subscriptions',
        'push-notifications': 'Push notifications',
        'resources': 'Resources',
        'text-templates': 'Text templates',
        'feedback-question': 'Questions',
        'feedback-questionnaire': 'Questionnaires',
        'feedback-answers': 'Answers',
        'labs': 'Labs',
        'expense': 'Expenses',
        'toppers': 'Toppers',
        'balance-reports': 'Balance reports',
        'payment-reports': 'Payment method reports',
        'appointment-reports': 'Appointment reports',
        'suppliers': 'Suppliers',
        'supplies': 'Supplies',
        'categories': 'Categories',
        'brands': 'Brands',
        'reports-summary': 'Summary reports',
        'invoices-summary': 'Invoice reports',
        'sources': 'Sources',
        'specialty': 'Specialty',
        'cdb': 'Clinical diagnosis',
        'notification': 'Notifications',
        'my-notifications': 'My notifications',
        'diagnose-objectively': 'Final diagnosis',
        'fields': 'Health card',
        'exam': 'Exams',
        'manipulations': 'Manipulations',
        'users': 'Users',
        'permissions': 'Permissions',
        'tooth': 'Teeth',
        'invoices': 'Invoices',
        'transactions': 'Transactions',
        'documents': 'Documents',
        'sms': 'SMS',
        'sessions': 'Active sessions',
        'payment-history': 'Payment history reports',
        'expense-reports': 'Expense reports',
    },
    'History': 'History',
    'loadAppointment': 'no records | one more record | {n} more records',
    'x-ray': {
        'ct_up_low_jaw': 'CT up jaw/ low jaw',
        'ct_up_low_tmj_jaw': 'CT up jaw/ low jaw/ temporomandibular joints',
        'ct_up_ms_jaw': 'CT up jaw/ maxillary sinuses',
        'tgr_lateral_projection': 'TRG in lateral projection',
        'tgr_front_projection': 'TRG in front projection',
        'tgr_subchinoparietal_projection': 'TRG in subchinoparietal projection',
        'section4_tmj': '4-section X-ray of temporomandibular joints',
        'section6_tmj': '6-section X-ray of temporomandibular joints',
    },
    'product': {
        'lowStock': '<b>{product.label}</b> running out of stock'
    },
    'examDefaults': {
        'objectivly': 'The face is symmetrical and proportionate. Facial skin and visible mucous membranes without visible pathological changes.',
        'OralMucosa': 'Pale pink mucosal mucosa, without visible pathological changes'
    },
    'treatment': {
        'print': 'Print'
    },
    'account_expired': "Your account has expired. Please contact support team <a href='https://32desk.com'>32desk.com</a>",
    'email.subject': 'Message from {company}',
    'questionnaire': {
        'questions_count': 'Questions: {n}',
    },
    'questionnaire-question': {
        'types': {
            'text': 'Text',
            'single-choice': 'Single choice',
            'multiple-choice': 'Multiple choice',
        }
    },
    'alias: value already exists.': 'This alias is already taken',
}
