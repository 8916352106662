export default {
    path: '/my-earnings',
    component: () => import('@/Layouts/Default.vue'),
    children: [
        { path: '', component: () => import('./MyEarnings.vue'), name: 'myEarnings' }
    ],
    meta: {
        requiresAuth: true
    }
};
