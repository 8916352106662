export default {
    path: '/login',
    component: () => import('@/Layouts/Login.vue'),
    children: [
        { path: '', name: 'login', component: () => import('./Login.vue') },
        { path: 'forgot-password', name: 'forgot-password', component: () => import('./Forgot.vue') },
        { path: 'logout', name: 'logout', component: () => import('./Logout.vue') },
        { path: 'reset-password', name: 'reset-password', component: () => import('./PasswordReset.vue') },
    ],
    meta: {},
};
