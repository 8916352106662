export default [
    '#FF9CBB',
    '#E2A6E6',
    '#A5DFF8',
    '#BBC1E8',
    '#a663cc',
    '#f4a261',
    '#f1f0cf',
    '#f0c9c9',
    '#003f5c',
    '#58508d',
    '#e76f51',
    '#68d8d6',
    '#bc5090',
    '#f4d35e',
    '#c6e2e9',
    '#d1b3c4',
    '#a3a380',
    '#b6e6bd',
    '#57cc99',
    '#4baea0',
    '#9bc53d',
    '#5bc0eb'
];
