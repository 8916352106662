export default {
    path: '/appointments',
    component: () => import('@/Layouts/Default.vue'),
    children: [
        {
            path: '', component: () => import('./Index.vue'),
            children: [
                { path: '', name: 'appointments', component: () => import('./Calendar.vue') },
                { path: 'filter/:filter', name: 'appointments.filter', component: () => import('./List.vue') },
                { path: 'status/:status', name: 'appointments.arrived', component: () => import('./List.vue') },
                { path: 'calendar', name: 'appointments.calendar', component: () => import('./Calendar.vue') },
                // {path: 'board', name: 'appointments.board', component: () => import('./Board.vue')},
            ]
        }
    ],
    meta: {
        requiresAuth: true,
    },
};
