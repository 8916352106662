export default {
    path: '/pos',
    component: () => import('@/Pos/Layout.vue'),
    children: [
        {
            path: '', component: () => import('./Index.vue'),
            name: 'pos'
        }
    ],
    meta: {
        requiresAuth: true,
    },
};
