export default {
    path: '/materials',
    component: () => import('@/Layouts/Default.vue'),
    children: [
        {
            path: '/', component: () => import('./Index.vue'),
            children: [
                {
                    path: '',
                    component: () => import('./ProductsLayout.vue'),
                    children: [
                        { path: '', name: 'materials', component: () => import('./Products.vue'), },
                        {
                            path: 'product/:productId/',
                            component: () => import('./Product/Index.vue'),
                            props: true,
                            children: [
                                // { path: 'general', name: 'materials.product', component: () => import('./Product/General.vue') },
                                { path: 'supplies', name: 'materials.product', component: () => import('./Product/List.vue') },
                            ]
                        }
                    ]
                },
                { path: 'categories', name: 'materials.categories', component: () => import('./Categories.vue') },
                { path: 'brands', name: 'materials.brands', component: () => import('./Brands.vue') },
                { path: 'suppliers', name: 'materials.suppliers', component: () => import('./Suppliers.vue') },
                { path: 'tobuy', name: 'materials.tobuy', component: () => import('./ToBuy.vue') },
                { path: 'waitlist', name: 'materials.waitlist', component: () => import('./Waitlist.vue') },
            ]
        }
    ],
    meta: {
        requiresAuth: true,
    },
};
