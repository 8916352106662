const colors = require('@/plugins/colors');

function setRandomColorIfNotSet() {
    return (context) => {
        if (context.path !== 'customers' || context.data.color) return;
        
        context.data.color = colors[Math.floor(Math.random() * colors.length)];
    };
}

module.exports = {
    setRandomColorIfNotSet
}