import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueMoment from 'vue-moment';
import BootstrapVue from 'bootstrap-vue';
import VueI18n from 'vue-i18n';
import { createI18n } from 'vue-i18n-composable';
import PortalVue from 'portal-vue';
import Permissions from './plugins/permissions';
import Modules from './plugins/modules';
import Utils from './plugins/utils';
import Vue2TouchEvents from 'vue2-touch-events';
import './toasts';
import './registerServiceWorker';
import ru from '@/translations/ru';
import en from '@/translations/en';
import ru1 from '@/translations/ru1';
import fi from '@/translations/fi';
import uz from '@/translations/uz';
// import tr from '@/translations/tr'
// import sv from '@/translations/sv'
/* Import Styles */
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/assets/fonts/feather/feather.css';
import '@/assets/css/theme.css';
import 'vue-tel-input/dist/vue-tel-input.css';
import "bootstrap-icons/font/bootstrap-icons.min.css";
Vue.use(Vue2TouchEvents);
const moment = require('moment');
let defaultLang = process.env.VUE_APP_DEFAULT_LANG ? process.env.VUE_APP_DEFAULT_LANG : 'ru';
VueI18n.prototype.getChoiceIndex = function (choice, choicesLength) {
    // this === VueI18n instance, so the locale property also exists here
    if (this.locale !== 'ru') {
        // proceed to the default implementation
    }
    if (choice === 0) {
        return 0;
    }
    const teen = choice > 10 && choice < 20;
    const endsWithOne = choice % 10 === 1;
    if (!teen && endsWithOne) {
        return 1;
    }
    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
    }
    return (choicesLength < 4) ? 2 : 3;
};
Vue.use(VueI18n);
Vue.use(PortalVue);
let messages = {
    en,
    ru,
    ru1,
    fi,
    uz
};
let settings = {
    currency: 'sum',
};
const i18n = createI18n({
    locale: defaultLang,
    messages,
    // @ts-ignore
    fallbackLocale: {
        'ru1': ['ru']
    },
});
Object.defineProperty(Vue.prototype, '$locale', {
    get: function () {
        return i18n.locale;
    },
    set: function (locale) {
        i18n.locale = locale;
    }
});
Object.defineProperty(Vue.prototype, '$settings', {
    get: function () {
        return settings;
    },
    set: function (newValue) {
        settings = newValue;
    }
});
Vue.use(BootstrapVue);
Vue.use(Permissions, { store: store });
Vue.use(Utils, { store: store });
Vue.use(Modules, { store: store });
Vue.use(VueMoment, {
    moment
});
Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        store.dispatch('auth/authenticate').then((response) => {
            next();
        }).catch((error) => {
            if (error.code === 401) {
                return next({
                    name: 'logout'
                });
            }
            next({
                name: 'login',
            });
        });
    }
    else {
        next();
    }
});
new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');
