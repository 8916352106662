<template>
    <div id="app" @click="mainClick">
        <b-toast
            id="update-pwa"
            toaster="b-toaster-top-center"
            :title="$t('New Update Available')"
            variant="warning"
            solid
            no-auto-hide
            no-close-button
        >
            <button @click="update" class="btn btn-white btn-block">{{ $t('Click to refresh') }}</button>
        </b-toast>
        <router-view />
    </div>
</template>

<script>
export default {

    data() {
        return {
            registration: null,
            isRefresh: false,
            refreshing: false,
        };
    },
    components: {},
    beforeMount() {
        const manifestLink = document.querySelector('link[rel="manifest"]');
        manifestLink.setAttribute('href', !this.$checkDevice() ? '/manifest_'+ process.env.VUE_APP_PROJECT_ASSETS_FOLDER +'_desktop.json' : '/manifest.json');
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.setCalculatedVH()
        });
    },
    beforeDestroy() {

    },
    created() {
        this.setCalculatedVH()
        navigator.serviceWorker.addEventListener(
            'controllerchange', () => {
                if (this.refreshing) return;
                this.refreshing = true;
                window.location.reload();
            }
        );
        document.addEventListener(
            'serviceWorkerUpdateEvent', this.appUpdateUI, { once: true }
        );
    },
    methods: {
        mainClick() {
            this.$root.$emit('bv::hide::popover');
        },
        appUpdateUI: function (e) {
            this.registration = e.detail;
            this.isRefresh = true;
            this.$bvToast.show('update-pwa');
        },
        update() {
            this.$bvToast.hide('update-pwa');
            this.isRefresh = false;
            if (this.registration || this.registration.waiting) {
                this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            }
        },
        setCalculatedVH() {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--calculated-vh', `${vh}px`);
        },
    },

}
</script>

<style lang="scss">
/* cyrillic */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Rubik"), local("Rubik-Regular"),
        url(https://fonts.gstatic.com/s/rubik/v8/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2)
            format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Rubik"), local("Rubik-Regular"),
        url(https://fonts.gstatic.com/s/rubik/v8/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2)
            format("woff2");
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Rubik"), local("Rubik-Regular"),
        url(https://fonts.gstatic.com/s/rubik/v8/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2)
            format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Rubik"), local("Rubik-Regular"),
        url(https://fonts.gstatic.com/s/rubik/v8/iJWKBXyIfDnIV7nBrXyw023e.woff2)
            format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}

#app {
    height: 100%;
    min-height: 100vh;
    font-family: "Rubik", sans-serif;
}

.no-glow:focus {
    outline-color: transparent;
    box-shadow: none !important;
    outline-style: none;
}

.pointer {
    cursor: pointer;
}

.hover:hover {
    background-color: #f9fbfd;
}

.draggable:active {
    cursor: move;
}

.card-header-title::first-letter {
    text-transform: uppercase;
}

h2 {
    text-transform: capitalize;
}

.service-draggable {
    cursor: pointer;
}

.service-draggable-icon {
    display: none;
}

.service-draggable:hover .service-draggable-icon {
    display: block;
}

*.unselectable {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;

    /*
     Introduced in Internet Explorer 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
    -ms-user-select: none;
    user-select: none;
}

.spin-icon {
    animation: spin-animation 1.1s infinite linear;
    display: inline-block;
    color: #003272;
}

@keyframes spin-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

.card .dropdown .dropdown-menu, .filter-vertical-modal .dropdown .dropdown-menu {
    max-height: 500px;
    overflow-y: auto;
}

#update-pwa {
    &.toast {
        margin: auto !important;
    }

    .toast-header {
        display: flex;
        justify-content: center;

        strong {
            padding: 5px;
        }
    }
}

@media print {
    @page {
        size: auto;
        margin: 12mm;
    }
    html, body {
        height:100vh;
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden;
        color: black;
        font-family: "Rubik", sans-serif;
    }
    body * {
        visibility: hidden;
    }
    .page-break {
        clear: both;
        page-break-before: always;
    }
    .never-page-break {
        break-inside: avoid!important;
    }
    .page-break-after {
        page-break-inside:avoid;
    }
    .never-print {
        display: none!important;
    }
    .container, .container-fluid {
        padding: 0!important;
        margin: 0!important;
        max-width: 99%!important;
    }
    #printSection, #printSection * {
        visibility: visible !important;
        z-index: 9999;
    }
    #printSection {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
    #printContent {
        min-width: 100% !important;
        width: 100% !important;
        max-width: 100% !important;
        position: absolute;
        left: 0;
        top: 0;
    }
    td {
        border-top-color: #000 !important;
    }
    thead tr {
        border-left: 0;
        border-top: 1px solid #000 !important;
        border-bottom: 1px solid #000 !important;
        color: #000;
    }
    thead th {
        border-top: 1px solid #000 !important;
        border-bottom: 1px solid #000 !important;
        background-color: #ccc !important;
        color: #000 !important;
    }
}

.position-absolute-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

</style>
