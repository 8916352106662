import PermissionCheck from './PermissionCheck.vue';
function checkActionPermission(permission, actions) {
    if (actions.includes('any')) {
        return ['view', 'create', 'edit', 'delete', 'seeAll'].some((action) => permission[action]);
    }
    return actions.every((action) => permission[action]);
}
/**
 * Permissions that haven't service (endpoint) in backend.
 */
export const customPermissions = ['customer-phone'];
export default {
    install(Vue, options = { store: null }) {
        Vue.component('permission-check', PermissionCheck);
        Vue.prototype.$hasPermission = (resource, actions = [
            'view', 'create',
            'edit', 'delete',
            'seeAll', 'any'
        ]) => {
            const permissions = options.store.state.auth.user.role.permissions;
            return permissions.some((permission) => {
                if (typeof resource === 'string') {
                    return (permission.resource === resource) && checkActionPermission(permission, actions);
                }
                else if (Array.isArray(resource)) {
                    return resource.includes(permission.resource) && checkActionPermission(permission, actions);
                }
            });
        };
    }
};
