export default {
    path: '/people',
    component: () => import('@/Layouts/Default.vue'),
    children: [
        { path: '', name: 'people', component: () => import('./Index.vue') },
        { path: 'team', name: 'people.team', component: () => import('./Team.vue') },
        { path: ':userId/balanceHistory', name: 'people.user.balanceHistory', component: () => import('./User/BalanceHistory.vue'), props: true },
        {
            path: 'customers', component: () => import('./Customers.vue'),
            children: [
                { path: '', name: 'people.customers', component: () => import('./Customers.vue') },
                { path: 'filter/:filter', name: 'people.customers.filter', component: () => import('./Customers.vue') },
            ]
        },
        {
            path: '/customer/:id', component: () => import('./Customer.vue'), props: true,
            children: [
                { path: '', name: 'people.customer', component: () => import('./Customer/Route.vue') },
                { path: 'treatment', name: 'people.customer.treatment', component: () => import('./Customer/Treatment.vue') },
                { path: 'treatment-plan', name: 'people.customer.t-plan', component: () => import('./Customer/TreatmentPlan/TreatmentPlans.vue') },
                { path: 'general', name: 'people.customer.general', component: () => import('./Customer/General.vue') },
                { path: 'appointments', name: 'people.customer.appointments', component: () => import('./Customer/Appointments.vue') },
                { path: 'services', name: 'people.customer.services', component: () => import('./Customer/Services.vue') },
                { path: 'timeline', name: 'people.customer.timeline', component: () => import('./Customer/Timeline.vue') },
                { path: 'notes', name: 'people.customer.notes', component: () => import('./Customer/Notes.vue') },
                { path: 'billing', name: 'people.customer.billing', component: () => import('./Customer/Billing.vue') },
                { path: 'storage', name: 'people.customer.storage', component: () => import('./Customer/Storage.vue') },
                { path: 'xrays', name: 'people.customer.xrays', component: () => import('./Customer/Xrays.vue') },
                { path: 'communications', name: 'people.customer.communications', component: () => import('./Customer/Communications/Communications.vue') },
                { path: 'transactions', name: 'people.customer.transactions', component: () => import('./Customer/Transactions.vue') },
                { path: 'labs', name: 'people.customer.labs', component: () => import('./Customer/CustomerLabs.vue') },
                { path: 'tasks', name: 'people.customer.tasks', component: () => import('./Customer/CustomerTasks.vue') },
                { path: 'questionnaires', name: 'people.customer.questionnaires', component: () => import('./Customer/Questionnaires/CustomerQuestionnaires.vue'), props: true },
            ]
        },
    ],
    meta: {
        requiresAuth: true,
    },
};
