export default {
    path: '/reports',
    component: () => import('@/Layouts/Default.vue'),
    children: [
        {
            // .
            path: '', component: () => import('./Index.vue'), props: true,
            children: [
                { path: '', name: 'reports', component: () => import('./Redirect.vue') },
                { path: 'summary', name: 'reports.summary', component: () => import('./Summary.vue') },
                { path: 'customers', name: 'reports.customers', component: () => import('./Customers.vue') },
                { path: 'appointments', name: 'reports.appointments', component: () => import('./Appointments.vue') },
                { path: 'services', name: 'reports.services', component: () => import('./Services.vue') },
                { path: 'products', name: 'reports.products', component: () => import('./Products.vue') },
                { path: 'payments', name: 'reports.payments', component: () => import('./Payments.vue') },
                { path: 'payouts', name: 'reports.payouts', component: () => import('./Payouts.vue') },
                { path: 'toppers', name: 'reports.toppers', component: () => import('./Toppers.vue') },
                { path: 'expenses', name: 'reports.expenses', component: () => import('./Expenses/Index.vue') },
                { path: 'history', name: 'reports.history', component: () => import('./History/Index.vue') },
                { path: 'feedback', name: 'reports.feedback', component: () => import('./Feedback/FeedbackReport.vue') },
            ]
        },
    ],
    meta: {
        requiresAuth: true,
    },
};
