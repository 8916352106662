export default {
    path: '/easy',
    component: () => import('@/Pages/Easy/Layout.vue'),
    children: [
        {
            path: '', component: () => import('./Index.vue'),
            name: 'easy',
            children: [
                { path: ':id', name: 'easy.single', component: () => import('./Index.vue') },
            ]
        },
    ],
    meta: {
        requiresAuth: true,
    },
};
