function checkActionPermission(permission, actions) {
    if (actions.includes('any')) {
        return ['view', 'create', 'edit', 'delete', 'seeAll'].some((action) => permission[action]);
    }
    return actions.every((action) => permission[action]);
}
export default {
    install(Vue, options = { store: null }) {
        Vue.prototype.$loadModule = (module) => {
            const company = options.store.getters['my-company/get']('mine');
            return company.module[module];
        };
    }
};
