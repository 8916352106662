export default {
    path: '/company',
    component: () => import('@/Layouts/Default.vue'),
    children: [
        {
            path: '', component: () => import('./Index.vue'),
            children: [
                { path: 'roles', name: 'company.roles', component: () => import('./Roles.vue') },
                { path: 'invoice', name: 'company.invoice', component: () => import('./Invoice.vue') },
                { path: 'services', name: 'company.services', component: () => import('./Treatments/Services.vue') },
                { path: 'icd/:parentId?', name: 'company.icd', component: () => import('./Icd.vue'), props: true },
                { path: 'specialty', name: 'company.specialty', component: () => import('./Specialty.vue'), props: true },
                { path: 'cdb/:icd?', name: 'company.cdb', component: () => import('./Cdb.vue'), props: true },
                { path: 'odb/:parentId?', name: 'company.odb', component: () => import('./Odb.vue'), props: true },
                { path: 'services/:serviceId', name: 'company.service', component: () => import('./Treatments/Services.vue'), props: true },
                { path: 'bundles', name: 'company.bundles', component: () => import('./Services/Bundles.vue'), props: true },
                { path: 'documents', name: 'company.documents', component: () => import('./Documents/Documents.vue'), props: true },
                { path: 'presets/:specialtyId?', name: 'company.presets', component: () => import('./Presets/Presets.vue'), props: true },
                { path: 'tasks', name: 'company.tasks', component: () => import('./Tasks/TasksList.vue'), props: true },
                { path: 'communications', name: 'company.communications', component: () => import('./Communications/Communications.vue'), props: true },
                { path: 'trash', name: 'company.trash', component: () => import('./Trash/TrashList.vue'), props: true },
                { path: 'sources', name: 'company.sources', component: () => import('./Sources/Sources.vue'), props: true },
                { path: 'manipulations', name: 'company.manipulations', component: () => import('./Manipulations/Manipulations.vue'), props: true },
                { path: 'payments', name: 'company.payments', component: () => import('./Payments/Payments.vue') },
                { path: 'z-payments', name: 'company.zpayments', component: () => import('./Payments/ZView.vue') },
                { path: 'userActivities', name: 'company.userActivities', component: () => import('./UserActivities/UserActivities.vue') },
                { path: 'tags', name: 'company.tags', component: () => import('./Tags/Tags.vue') },
                { path: 'notifications', name: 'company.notifications', component: () => import('./Notifications.vue') },
                { path: 'dispatch', name: 'company.dispatch', component: () => import('./Dispatch.vue') },
                { path: 'labs', name: 'company.labs', component: () => import('./Labs/Labs.vue') },
                { path: 'work-places', name: 'company.work-places', component: () => import('./WorkPlaces/WorkPlaces.vue') },
                { path: 'expenses', name: 'company.expenses', component: () => import('./Expenses/Expenses.vue') },
                { path: 'configurator', name: 'company.configurator', component: () => import('./Configurator/Configurator.vue') },
                { path: 'feedback-questionares', name: 'company.feedbacks-control', component: () => import('./Feedbacks/Index.vue'),
                    children: [
                        {
                            path: '', name: 'feedbacks.questionnaires', component: () => import('./Feedbacks/FeedbackQuestionnaires.vue')
                        },
                        {
                            path: ':questionnaireId', name: 'feedbacks.questionnaires.details', component: () => import('./Feedbacks/FeedbackQuestionnaireDetails.vue'), props: true
                        }
                    ]
                },
                { path: 'text-templates', name: 'company.textTemplates', component: () => import('./TextTemplates/TextTemplates.vue'), props: true },
                { path: 'session', name: 'company.sessions', component: () => import('./Sessions/Index.vue') },
                { path: 'media', name: 'company.media', component: () => import('./Media/Index.vue') },
                {
                    path: 'profile', component: () => import('./Navigation.vue'),
                    children: [
                        { path: '', name: 'company.profile', component: () => import('./General.vue') },
                        { path: 'billing', name: 'company.profile.billing', component: () => import('./Billing.vue') },
                        { path: 'settings', name: 'company.profile.settings', component: () => import('./Settings.vue') },
                        { path: 'schedule', name: 'company.profile.schedule', component: () => import('./Schedule.vue') },
                        { path: 'builder', name: 'company.profile.builder', component: () => import('./Builder.vue') },
                        { path: 'socials', name: 'company.profile.socials', component: () => import('./Socials.vue') },
                        { path: 'contacts', name: 'company.profile.contacts', component: () => import('./Contacts.vue') },
                        { path: 'notifications', name: 'company.profile.notifications', component: () => import('./Notifications/Notifications.vue') },
                        { path: 'notification-templates', name: 'company.profile.notificationTemplates', component: () => import('../Settings/Company/NotificationMessageTemplates/NotificationTemplates.vue') },
                        { path: 'printTemplates', name: 'company.profile.printTemplates', component: () => import('./PrintTemplates.vue') },
                        { path: 'integrations', name: 'company.profile.integrations', component: () => import('./Integrations.vue') },
                        { path: 'online-registration', name: 'company.profile.onlineRegistration', component: () => import('./OnlineRegistration.vue') },
                        { path: 'language-region', name: 'company.profile.languageAndRegion', component: () => import('./LanguageAndRegion.vue') },
                    ]
                }
            ],
        }
    ],
    meta: {
        requiresAuth: true,
    },
};
