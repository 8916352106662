export default {
    path: '/board',
    component: () => import('@/Layouts/Default.vue'),
    children: [
        {
            path: '', name: 'board', component: () => import('./Board.vue')
        }
    ],
    meta: {
        requiresAuth: true
    }
};
