import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'
import io from 'socket.io-client'
import hooks from './feathers-hooks'

const socket = io(process.env.VUE_APP_API_ENDPOINT, {
    transports: ['websocket']
});

console.log(process.env.VUE_APP_API_ENDPOINT);

const feathersClient = feathers()
    .configure(socketio(socket, { timeout: 30000 }))
    .configure(auth({
        storage: window.localStorage,
        timeout: 30000
    }));

feathersClient.hooks(hooks);

export default feathersClient
