<template>
    <span>
        <slot v-if="hasPermission"></slot>
    </span>
</template>

<script>
    export default {
        name: "PermissionCheck",
        props: {
            resource: String,
            view: Boolean,
            create: Boolean,
            edit: Boolean,
            delete: Boolean,
            seeAll: Boolean,
            any: Boolean
        },
        methods: {},

        computed: {
            hasPermission() {
                let actions = [];
                if (this.view) {
                    actions.push('view')
                }
                if (this.create) {
                    actions.push('create')
                }
                if (this.edit) {
                    actions.push('edit')
                }
                if (this.delete) {
                    actions.push('delete')
                }
                if (this.seeAll) {
                    actions.push('seeAll')
                }
                return this.$hasPermission(this.resource, actions)
            }
        }
    }
</script>

<style scoped>

</style>
